import classNames from "classnames";
import styles from "components/featuresSection/FeaturesSection.module.scss";
import PillLabel from "components/pillLabel/PillLabel";
import { ReactNode } from "react";
import Lottie from "lottie-react";

type FeatureElementProps = {
  title: string | ReactNode;
  label: string;
  lottieData?: Object;
};

export function FeatureElement(props: FeatureElementProps) {
  return (
    <div className={styles.featureElementWrapper}>
      <div className={styles.featureElementContainer}>
        <div className={styles.TextWrapper}>
          <PillLabel label={props.label} />
          <div className={styles.Title}>{props.title}</div>
        </div>
        {props.lottieData && <FeatureLottie data={props.lottieData} />}
      </div>
    </div>
  );
}

export function FeatureLottie(props: { data: any }) {
  return (
    <div className={styles.illustrationWrapper}>
      <div className={styles.featureIllustrationVideoContainer}>
        {props.data && <Lottie animationData={props.data} />}
      </div>
    </div>
  );
}

export function FeaturesSectionHeader(props: {
  title: string | ReactNode;
  titleAlignLeft?: boolean;
}) {
  return (
    <h2
      className={classNames(styles.featuresSectionHeader, {
        [styles.titleLeft]: props.titleAlignLeft,
      })}
    >
      {props.title}
    </h2>
  );
}
