import styles from "components/pageHeading/PageHeading.module.scss";

export function PageHeading(props: { header: string }) {
  return (
    <div className={styles.pageHeadingWrapper}>
      <div className={styles.pageHeadingContainer}>
        <h1 className={styles.header}>{props.header}</h1>
      </div>
    </div>
  );
}
