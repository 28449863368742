import styles from "components/testimonials/Testimonials.module.scss";
import { ReactComponent as TwentyOneEnergyLogo } from "components/testimonials/logos/21energy-logo.svg";
import { ReactComponent as EbeaLogo } from "components/testimonials/logos/ebea-logo.svg";
import { ReactComponent as FulgurLogo } from "components/testimonials/logos/fulgur_logo_white.svg";
import { ReactComponent as RunWayLogo } from "components/testimonials/logos/runway-logo.svg";
import { ReactComponent as SeeteeLogo } from "components/testimonials/logos/seetee-logo.svg";
import { ReactComponent as WolfLogo } from "components/testimonials/logos/wolf-logo.svg";

export function Testimonials() {
  return (
    <div className={styles.testimonialsWrapper}>
      <div className={styles.label}>Trusted by</div>
      <div className={styles.testimonialsGrid}>
        <div className={styles.wideLogo}>
          <a href="https://ebea.work/">
            <EbeaLogo title="EBEA - European Bitcoin Energy Association" />
          </a>
        </div>
        <div className={styles.wideLogo}>
          <a href="https://21energy.com/">
            <TwentyOneEnergyLogo title="21 Energy" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://wolfnyc.com/">
            <WolfLogo title="Wolf | Bitcoin Accelerator" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://fulgur.ventures/">
            <FulgurLogo title="Fulgur Ventures" />
          </a>
        </div>
        <div className={styles.wideLogo}>
          <a href="https://runwayfbu.com/">
            <RunWayLogo title="Runway FBU" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://www.seetee.io/">
            <SeeteeLogo title="seetee" />
          </a>
        </div>
      </div>
    </div>
  );
}
