import {
  AboutElementSideBySide,
  AboutElementTeam,
  AboutFlowChartIllustration,
  AboutSectionHeader,
} from "components/aboutSection/AboutSection";
import { PageHeading } from "components/pageHeading/PageHeading";
import { SectionWrapper } from "components/section/Section";
import styles from "pages/About/AboutPage.module.scss";
import flowchart from "animations/about/flowchart.mp4";

export default function AboutPage() {
  return (
    <div className={styles.homePageWrapper}>
      <PageHeading header="About us" />
      <SectionWrapper>
        <AboutSectionHeader titleAlignLeft />
        <AboutElementSideBySide
          title={
            <span>
              <span>
                What drives us.
              </span>
              <div className={styles.hideMobile}>
                <AboutFlowChartIllustration animationPath={flowchart} />
              </div>
            </span>
          }
        >
          <p>
            At Mion, we are passionate about Bitcoin and its transformative
            potential for the world. We believe in its power to create a better,
            more efficient financial system and are committed to driving Europe
            forward into this new era.
          </p>
          <p>
            Our mission is to make Bitcoin transactions fast, secure, and
            accessible for merchants. We offer seamless Bitcoin to Euro
            conversions, full regulatory compliance, and rapid transaction
            speeds, eliminating integration hurdles and simplifying the payment
            process.
          </p>
          <p>
            What sets us apart is our world-class Lightning node management
            tool, making Bitcoin payments viable for the first time. The
            Lightning Network enables instant, low-cost transactions, unlocking
            the true potential of Bitcoin for everyday use. This groundbreaking
            technology ensures that Bitcoin transactions are not only fast and
            efficient but also scalable and practical for businesses.
          </p>
          <p>
            We are dedicated to making digital currency easy to use and
            integrate, helping businesses reduce costs and streamline
            operations. Our advanced technology and frictionless onboarding
            ensure that our clients can quickly and efficiently adopt Bitcoin
            payments.
          </p>
          <p>
            At Mion, we believe in the future of Bitcoin and its ability to
            revolutionize the financial landscape. Join us in our mission to
            harness the power of Bitcoin and drive innovation and growth across
            Europe. Together, we can create a better, more connected world
          </p>
        </AboutElementSideBySide>
        <AboutElementTeam />
      </SectionWrapper>
    </div>
  );
}
