import { Link, useLocation } from "react-router-dom";
import { About, Home } from "Router";
import styles from "./TopNavigation.module.scss";
// import { ReactComponent as Logo } from "images/mion-logo.svg";
import lottie, { AnimationItem } from "lottie-web";
import LogoData from "animations/home/mion_logo_animation_alt.json";
import { useEffect, useRef, useState } from "react";

import classNames from "classnames";

export default function TopNavigation() {
  const logoReg = useRef<HTMLDivElement>(null);

  const [anim, setAnim] = useState<AnimationItem>();

  const linkWrapperRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    // scroll to the top of the page slowly
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  // use effect to set anim
  useEffect(() => {
    if (logoReg.current) {
      const a = lottie.loadAnimation({
        container: logoReg.current,
        animationData: LogoData,
        renderer: "svg",
        loop: false,
        autoplay: true,
      });
      setAnim(a);
      return () => {
        a.destroy(); // Clean up animation on component unmount
      };
    }
  }, []);

  // On click reverse the animation
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (logoReg.current && anim) {
      // If the direction is reversed, play the animation normally
      if (anim.playDirection === -1) {
        anim.setDirection(1);
        anim.setSpeed(2);
        anim.goToAndPlay(50, true);
      } else {
        // If the direction is normal, play the animation in reverse
        anim.setDirection(-1);
        // anim.setSpeed(2);
        // Skip back 100 milliseconds from the end
        anim.goToAndPlay(225, true);
      }
    }
  };

  // listen for anmation reverse complete then start it again
  useEffect(() => {
    if (anim) {
      anim.addEventListener("complete", () => {
        if (anim.playDirection === -1) {
          anim.setDirection(1);
          // anim.setSpeed(2);
          anim.goToAndPlay(50, true);
        }
      });

      // Clean up event listener on component unmount
      return () => {
        anim.removeEventListener("complete", () => {
          if (anim.playDirection === -1) {
            anim.setDirection(1);
            anim.goToAndPlay(50, true);
          }
        });
      };
    }
  }, [anim]);

  // create a div with type that includes "popover" attribute
  function openLinks() {
    linkWrapperRef?.current?.classList.add(styles.open);
    // if closing class is present, delete it
    if (linkWrapperRef?.current?.classList.contains(styles.closing)) {
      linkWrapperRef?.current?.classList.remove(styles.closing);
    }
  }

  function closeLinks() {
    linkWrapperRef?.current?.classList.add(styles.closing);
    // wait until slideOut animation is done to remove the "open" class
    linkWrapperRef?.current?.addEventListener(
      "animationend",
      function () {
        linkWrapperRef?.current?.classList.remove(styles.open);
      },
      { once: true }
    );
  }

  return (
    <nav className={styles.topNavigationWrapper}>
      <div className={styles.topNavigationContainer}>
        <Link className={styles.navLogo} to={Home}>
          <div ref={logoReg} onClick={onClick} />
        </Link>
        <div
          ref={menuRef}
          onClick={openLinks}
          className={classNames(styles.mobileMenuToggle)}
        >
          Menu
        </div>
        <div
          className={classNames(styles.linkWrapper, styles.closing)}
          ref={linkWrapperRef}
          id={"menu"}
          onClick={closeLinks}
        >
          <div className={styles.linkItems}>
            <Link className={styles.linkItem} to={Home}>
              Home
            </Link>
            <Link className={styles.linkItem} to={About}>
              About
            </Link>
            <a
              className={styles.linkItem}
              href={"https://docs.mion.group"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Documentation
            </a>
            <a
              className={styles.linkItem}
              href={"https://torq.co"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Torq
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

// // Keeping these around as examples
//
// // Define the type for the extended div with the custom popover attribute
// type ExtendedDivProps = HTMLAttributes<HTMLDivElement> & {
//   popover?: string;
// };
//
// // Create the ExtendedDiv component
// const ExtendedDiv = forwardRef<HTMLDivElement, ExtendedDivProps>(({ popover, ...props }, ref) => (
//   <div {...props} ref={ref} {...(popover ? { popover } : {})} />
// ));
//
// // Button popover-target
// type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
//   popoverTarget?: string;
// };
//
// // Create the Button component
// const ButtonPopoverTaget = forwardRef<HTMLButtonElement, ButtonProps>(({ popoverTarget, ...props }, ref) => (
//   <button {...props} ref={ref} {...(popoverTarget ? { popoverTarget } : {})} />
// ));
