import Footer from "components/footer/Footer";
import {Outlet} from "react-router-dom";
import Background from "components/background/Background";
import styles from "layout/Default/WebsiteLayout.module.scss";
import TopNavigation from "components/navigation/TopNavigation";
import CookieConsent from "components/cookieConsent/CookieConsent";

export default function WebsiteLayout() {
  return (
    <div className={styles.websiteWrapper}>
      <Background/>
      <TopNavigation />
      <div className={styles.expandMiddle}>
        <Outlet/>
      </div>
      <Footer/>
    </div>
  );
}