import styles from './Footer.module.scss';
import commonStyles from 'styles/common.module.scss';
import { Link } from 'react-router-dom';
import {About} from "Router";
import classNames from "classnames";

export default function Footer() {

  // on click open the cookie consent
  const handleClick = () => {
    const cookieConsent = document.getElementById("cookieConsent");
    if (cookieConsent) {
      cookieConsent.style.display = "block";
    }
  }


  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerContainer}>
        <div className={styles.messageContainer}>
          <div className={styles.message}>
            {"Accept bitcoin,"}
            <span className={commonStyles.grayText}>{" without bitcoin"}</span>
          </div>
          <div className={styles.actionButtons}>
            <a href={"mailto:hello@mion.group"} className={styles.actionButton}>
              {"Get in touch"}
            </a>

          </div>
        </div>
        <div className={styles.linkContainer}>
          <div className={styles.linkWrapper}>
            <nav className={styles.footerLinks}>
              <a href={"https://docs.mion.group"} className={styles.footerLinkItem}>Documentation</a>
              <a href={"https://app.termly.io/policy-viewer/policy.html?policyUUID=c577b736-316e-4fc7-abe5-5ec1b998c37c"} className={styles.footerLinkItem}>Privacy Policy</a>
              <a href={"https://app.termly.io/policy-viewer/policy.html?policyUUID=cf508fe3-1568-4de1-9c2b-2442407aceb5"} className={styles.footerLinkItem}>Terms & Conditions</a>
              <a href={"https://app.termly.io/policy-viewer/policy.html?policyUUID=0ab23f83-418e-44ed-a3d6-e9d0dc97f89b"} className={styles.footerLinkItem}>Cookie Policy</a>
              <a className={styles.footerLinkItem} data-cc="show-preferencesModal">{"Manage Cookies"}</a>
              <Link to={About} className={styles.footerLinkItem}>About</Link>
            </nav>
          </div>
        </div>
        <div  className={styles.disclaimer}>
          <div  className={styles.disclaimerWrapper}>
            The information and services described on this website are intended for residents of countries where we are authorized to operate and are provided for informational purposes only. This website is not intended to solicit or offer financial services to residents of jurisdictions where such services are not permitted by law. If you are accessing this website from a country where we are not authorized to provide financial services, please be aware that any information provided herein is not an offer or solicitation of financial services to residents of that country.
          </div>
        </div>
      </div>
    </div>
  );
}