import {RouteObject, useLocation, useRoutes} from "react-router-dom";
import WebsiteLayout from "layout/Default/WebsiteLayout";
import HomePage from "pages/Home/HomePage";
import { Suspense } from "react";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import AboutPage from "./pages/About/AboutPage";

export const Home = ""
export const About = "/about"

const publicRoutes: RouteObject = {
  element: <WebsiteLayout />,
  children: [
    { path: Home, element: <HomePage /> },
    { path: About, element: <AboutPage /> },
  ],
};

const AppRouter = () => {
  const location = useLocation();
  let background = location.state && location.state.background;

  // TODO: Keep this for future reference, we're most likely going to add modals in the future
  // If we do not have a background, and we are on a modal route, we should default the background to the root
  //   (modalRoutes?.children || []).forEach((route) => {
  //     const path = route.path || "";
  //     const regexPattern = path.replace(/:[^/]+/g, "[^/]+");
  //     const regex = new RegExp(`^${regexPattern}$`);
  //
  //     if (regex.test(location.pathname) && !background) {
  //       background = "/";
  //     }
  //   });
  const currentLocation = background || location;

  const routes = [publicRoutes];

  const router = useRoutes(routes, currentLocation);
  const modalRouter = useRoutes([publicRoutes]); //modalRoutes, authenticatedRoutes

  return (
    <Suspense fallback={<LoadingPage />}>
      {router}
      {background && modalRouter}
    </Suspense>
  );
};

export default AppRouter;