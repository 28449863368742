import {
  FeatureElement,
  FeaturesSectionHeader,
} from "components/featuresSection/FeaturesSection";
import styles from "pages/Home/HomePage.module.scss";
import commonStyles from "styles/common.module.scss";
import {
  KeyFigure,
  KeyFiguresGrid,
  KeyFiguresHeader,
  KeyFiguresSection,
} from "components/keyFigures/KeyFigures";
import { HomeHeading } from "components/homeHeading/HomeHeading";
import { SectionWrapper } from "components/section/Section";
import familiarIntegrationIllustration from "animations/home/familiar_integration.json";
import regulationSolvedIllustration from "animations/home/regulation_solved_transparent.json";
import noVolatilityIllustration from "animations/home/no_volatility.json";
import accountingAsUsualIllustration from "animations/home/accounting_as_usual.json";

export default function HomePage() {
  return (
    <div className={styles.homePageWrapper}>
      <HomeHeading />
      <SectionWrapper>
        <FeaturesSectionHeader
          title={
            // <div className={classNames(commonStyles.grayText)}>
            //   Mion unleashes Europe's{" "}
            //   <br className={commonStyles.hideOnMobile} />
            //   <span className={commonStyles.blackText}>€130 billion</span>{" "}
            //   bitcoin market
            // </div>
            <div>
              Mion unleashes Europe's{" "}
              <br className={commonStyles.hideOnTablet} />
              €130 billion Bitcoin market
            </div>
          }
          titleAlignLeft
        />
        <FeatureElement
          title={
            <span>
              <span className={commonStyles.grayText}>Integrates like</span>{" "}
              <span>standard payment solutions.</span>
            </span>
          }
          label={"Familiar integration"}
          lottieData={familiarIntegrationIllustration}
        />
        <FeatureElement
          title={
            <span>
              <span>Fully compliant and</span>{" "}
              <br className={commonStyles.hideOnMobile} />
              <span> authorized.</span>{" "}
              {/*<span className={commonStyles.grayText}>throughout Europe.</span>*/}
            </span>
          }
          label={"Regulation solved"}
          // illustrationPath={regulationSolvedIllustration}
          lottieData={regulationSolvedIllustration}
        />
        <FeatureElement
          title={
            <span>
              <span>Payouts in Euros,</span>{" "}
              <span className={commonStyles.grayText}>within 48 hours.</span>
            </span>
          }
          label={"Accounting as usual"}
          lottieData={accountingAsUsualIllustration}
        />
        <FeatureElement
          title={
            <span>
              <span>Instant Bitcoin to Euro conversion, </span>{" "}
              <span className={commonStyles.grayText}>everytime.</span>
            </span>
          }
          label={"No volatility"}
          lottieData={noVolatilityIllustration}
        />
      </SectionWrapper>
      <KeyFiguresSection>
        <KeyFiguresHeader
          label={"Key figures"}
          title={
            <span>
              <span>Allow your customers to pay in Bitcoin</span>{" "}
              <span className={commonStyles.grayTextOnNonWhite}>
                with lightning fast payments, secure, efficient and risk free
                while always receiving Euros.
              </span>
            </span>
          }
          titleAlignLeft
        />
        <KeyFiguresGrid>
          <KeyFigure value={"1.0%"} label={"only 1% per transaction"} />
          <KeyFigure value={"80%"} label={"save 80% in transaction fees"} />
          <KeyFigure value={"37m"} label={"attract 37 million new customers"} />
          <KeyFigure
            value={"€130B"}
            label={"tap into 130 billion € worth of bitcoin"}
          />
        </KeyFiguresGrid>
      </KeyFiguresSection>
    </div>
  );
}
