import styles from "components/section/Section.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";

type WrapperProps = {
  children: ReactNode;
  transparentSection?: boolean;
  homeHeadingSection?: boolean;
};

export function SectionWrapper(props: WrapperProps) {
  return (
    <div className={styles.sectionWrapper}>
      <div
        className={classNames(styles.sectionContainer,styles.boxSectionContainer)}
      >
        {props.children}
      </div>
    </div>
  );
}
