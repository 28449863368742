import * as CC from "vanilla-cookieconsent";
import "components/cookieConsent/vanilla-cookieconsent.css";
// import ReactGA from "react-ga4";
import { useEffect } from "react";

declare global {
  interface Window {
    clarity: (consent: string, on?: boolean) => void;
  }
}

export default function CookieConsent() {

  useEffect(() => {
    CC.run({
      revision: 1,
      cookie: {
        name: "cookieconsent_mion_website",
        sameSite: "Lax",
        useLocalStorage: false,
      },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {
          services: {
            something: {
              label: "Something",
              onAccept: () => {
                // If Google Analytics is not initialized, initialize it upon consent
                // if (!ReactGA.isInitialized) {
                //   ReactGA.initialize("G-LQBBBDKF14");
                // }
              },
              onReject: () => {
                // ReactGA.reset();
                // Delete GA cookies
                // document.cookie = "_ga=; Max-Age=0";
                // document.cookie = "_gid=; Max-Age=0";
                // document.cookie = "_gat=; Max-Age=0";
                // document.cookie = "_gac_...=; Max-Age=0";
              },
            }
        //     ga: {
        //       label: "Google Analytics",
        //       onAccept: () => {
        //         // If Google Analytics is not initialized, initialize it upon consent
        //         if (!ReactGA.isInitialized) {
        //           ReactGA.initialize("G-LQBBBDKF14");
        //         }
        //       },
        //       onReject: () => {
        //         ReactGA.reset();
        //         // Delete GA cookies
        //         document.cookie = "_ga=; Max-Age=0";
        //         document.cookie = "_gid=; Max-Age=0";
        //         document.cookie = "_gat=; Max-Age=0";
        //         document.cookie = "_gac_...=; Max-Age=0";
        //       },
        //     },
        //     clarity: {
        //       label: "Clarity",
        //       onAccept: () => {
        //         if (window?.clarity) {
        //           window.clarity("consent");
        //         }
        //       },
        //       onReject: () => {
        //         if (window?.clarity) {
        //           window.clarity("consent", false);
        //         }
        //       },
        //     },
          },
        },
      },
      language: {
        default: "en",
        translations: {
          en: "/locales/en/cookieConsent.json",
        },
      },
    });

    // if (CC.acceptedService("Google Analytics", "analytics") && !ReactGA.isInitialized) {
    //   ReactGA.initialize("G-LQBBBDKF14");
    // }

    // if (CC.acceptedService("Clarity", "analytics")) {
    //   if (window?.clarity) {
    //     window.clarity("consent");
    //   }
    // }
  }, []);

  return <></>;
}
